import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [];

export const dictionary = {
		"/(landing)": [14,[3]],
		"/(app)/claim": [4,[2]],
		"/(app)/donate": [5,[2]],
		"/(app)/fuel": [6,[2]],
		"/(app)/game": [7,[2]],
		"/(app)/history": [8,[2]],
		"/(app)/home": [9,[2]],
		"/(app)/jackpot": [10,[2]],
		"/(app)/mission": [11,[2]],
		"/(app)/referral": [12,[2]],
		"/(app)/release": [13,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';